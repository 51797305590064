import React, { useState, useEffect } from 'react';
import logo from './images/shs_logo.png';
import "./styles.css";
import Navbar from 'react-bootstrap/Navbar'
import { Box, Image } from '@chakra-ui/react';
import { Link as RouterLink, NavLink, Outlet  } from 'react-router-dom';
import DataGrid from './DataGrid';
import Footer from './Footer';
import ReactGA from 'react-ga4';
import one from './images/one.png';
import two from './images/two.png';
import three from './images/three.png';
import four from './images/four.png';

const filterDict = {
  "one": "Work anywhere",
  "two": "Low requirements",
  "three": "Build a business",
  "four": "Gig work",
  "": ""
}

function Home({data, companyorder}) {
  const [filterSelected, setFilterSelected] = useState("");
  const [scrolled, setScrolled] = useState(false);

  // scrolling for shadow on filter navbar
  useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY > window.innerHeight - 400) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  const handleFilterSelected = (selection) => {
    if (filterSelected === selection) {
      setFilterSelected("");
    } else {
      setFilterSelected(selection);
    }
  }
  // handle Newsletter link click
  const handleNewsletterClick = () => {
    ReactGA.event({
      category: 'Links',
      action: 'Newsletter',
      label: 'Newletter clicked!',
      value: 1
    });
  }

  return (
    <div className="Home">
      <div className="background">
        <Navbar>
            <div className="container px-5">
                <NavLink className="navbar-brand" to="/"><Image boxSize="50px" src={logo} /></NavLink>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <RouterLink className="nav-link" to="/about" end>
                        About
                      </RouterLink> 
                      <a className="nav-link" onClick={() => handleNewsletterClick()}  target="__blank" without rel="noreferrer" href='https://sidehustlestack.substack.com/' end>
                        Newsletter ↗
                      </a> 
                    </ul>
                </div>
            </div>
        </Navbar>
        <Box as={"header"} position="relative" height="300px">
            <div className="container px-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-left my-5">
                            <h1 className="display-6 fw-bolder text-white mb-2">Side Hustle Stack is the ultimate database of earning opportunities.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
        </div>
        <Box
          class="child"
          display="flex" // Add display flex
          justifyContent="center" // Center horizontally
          mt="-6rem" // Add padding to the bottom for displacement
        >
          <Box
            className='subscribe-box'
            textAlign="center"
            borderWidth="1px"
            borderColor="black"
            width={["calc(85% - 2rem)", "calc(85% - 4rem)",  "calc(85% - 4rem)", "calc(85% - 30rem)"]} // Adjust the width to match the other container based on screen size
            p={5}
            backgroundColor="white"
            borderRadius="6px"
            padding={["20px", "30px", "40px"]} // Adjust padding based on screen size
            marginBottom={["20px", "40px"]} // Add more bottom padding on mobile; use 40px for larger screens 
          >
            <p >
            You deserve more thoughtful conversations around earning. 
            </p>
            <p>Subscribe to our newsletter:</p>
            <iframe className="substack-frame" title="substack email signup" allowtransparency="true" height="100px" background-color="#880808" src="https://sidehustlestack.substack.com/embed" frameBorder="0" scrolling="no"></iframe>
          </Box>
        </Box>
        <section className={`py-3 border-bottom`}>
          <div className={`header ${scrolled ? "filters-shadow" : ""}`}>
            <div className="container">
              <div className="grid-container">
                <button className={`grid-item filter ${filterSelected === "one" ? "selected" : "unselected"}`} onClick={() => handleFilterSelected("one")}>
                  <img src={one} alt="filter 1" />
                  <p>Work anywhere</p>
                </button>
                <button className={`grid-item filter ${filterSelected === "two" ? "selected" : "unselected"}`} onClick={() => handleFilterSelected("two")}>
                  <img src={two} alt="filter 2" />
                  <p>Low requirements</p>
                </button>
                <button className={`grid-item filter ${filterSelected === "three" ? "selected" : "unselected"}`} onClick={() => handleFilterSelected("three")}>
                  <img src={three} alt="filter 3" />
                  <p>Build a Business</p>
                </button>
                <button className={`grid-item filter ${filterSelected === "four" ? "selected" : "unselected"}`} onClick={() => handleFilterSelected("four")}>
                  <img src={four} alt="filter 4" />
                  <p>Gig Work</p>
                </button>
              </div>
            </div>
          </div>
          <div className="container">
            <DataGrid companyorder={companyorder} data={data} filter={filterDict[filterSelected]} />
          </div>
        </section>
        <section>
          <Footer/>
        </section>
      <Outlet/>
    </div>
  );
}

export default Home;
